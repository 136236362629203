import React from "react"
const Video = ({ videoSrcURL, videoTitle, poster, ...props }) => (
  <video controls poster={poster}>
    <source
      src={videoSrcURL}
      type="video/mp4"
      preload="none"
      controlsList="nofullscreen nodownload"
    />
  </video>
)
export default Video
