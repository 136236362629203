import React from "react"
import { Trans, useTranslation, useI18next } from "gatsby-plugin-react-i18next"
import { faChevronDown } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import googleplay from "../images/google-play-button.svg"
import appstore from "../images/app-store-button.svg"

import Video from "../components/video"

import Layout from "../layouts/layout"
import SEO from "../layouts/seo"

const IndexPage = () => {
  const { language } = useI18next()

  const { t } = useTranslation()

  return (
    <Layout variant="home">
      <SEO title="Home" />

      <article className="home" id="start-now">
        <header className="home__header">
          <div class="home__boxes">
            <div class="home__slogan-box"></div>
            <div class="home__download-box"></div>
          </div>

          <div class="home__iphone">
            <figure>
              <img src={`/hero-banner-${language}.png`} alt="app preview" />
            </figure>
          </div>

          <div class="home__slogan-content">
            <h1>
              <small>{t("hero.header-part1")} </small>
              <strong>{t("hero.header-part2")}</strong>
              <span className="benefit-info">
                <Trans>hero.second-header</Trans>
              </span>
            </h1>
          </div>

          <div class="home__download-content">
            <div class="home__download-apps">
              <div class="buttons">
                <a href="https://play.google.com/store/apps/details?id=de.tiramizoo.driverapp_trmz">
                  <img src={googleplay} alt="app preview" />
                </a>
                <a href="https://apps.apple.com/us/app/id1525875219">
                  <img src={appstore} alt="app preview" />
                </a>
              </div>

              <p>
                <Trans>hero.download</Trans>
              </p>
            </div>
            <h2 className="small-slogan">
              <Trans>hero.right-paragraph-2</Trans>
              <span>
                &nbsp;<Trans>hero.right-paragraph-2-app</Trans>
              </span>
            </h2>
          </div>

          <a href="#scroll-to" className="home__scroll-down">
            <FontAwesomeIcon icon={faChevronDown} />
          </a>
        </header>

        <div id="scroll-to" className="home__video">
          <Video
            poster={`/poster-video-${language}.jpg`}
            videoSrcURL={`https://cad-public-assets.s3.eu-central-1.amazonaws.com/videos/tiramizoo-collect-${language}.mp4`}
            videoTitle="Collect & Dliver App"
          />
        </div>

        <ol className="home__steps">
          <li className="home__step">
            <div className="home__img">
              <figure>
                <img src={`/steps/step1-${language}.png`} alt="Step1" />
              </figure>
            </div>
            <div className="home__txt">
              <h2>
                <Trans>home-steps.step-1-header</Trans>
              </h2>
              <p>
                <Trans>home-steps.step-1-paragraph</Trans>
              </p>
            </div>
          </li>

          <li className="home__step">
            <div className="home__img">
              <figure>
                <img src={`/steps/step2-${language}.png`} alt="Step2" />
              </figure>
            </div>
            <div className="home__txt">
              <h2>
                <Trans>home-steps.step-2-header</Trans>
              </h2>
              <p>
                <Trans>home-steps.step-2-paragraph</Trans>
              </p>
            </div>
          </li>

          <li className="home__step">
            <div className="home__img">
              <figure>
                <img src={`/steps/step3-${language}.png`} alt="Step3" />
              </figure>
            </div>
            <div className="home__txt">
              <h2>
                <Trans>home-steps.step-3-header</Trans>
              </h2>
              <p>
                <Trans>home-steps.step-3-paragraph</Trans>
              </p>
            </div>
          </li>

          <li className="home__step">
            <div className="home__img">
              <figure>
                <img src={`/steps/step4-${language}.png`} alt="Step4" />
              </figure>
            </div>
            <div className="home__txt">
              <h2>
                <Trans>home-steps.step-4-header</Trans>
              </h2>
              <p>
                <Trans>home-steps.step-4-paragraph</Trans>
              </p>
            </div>
          </li>

          <li className="home__step">
            <div className="home__img">
              <figure>
                <img src={`/steps/step5-${language}.png`} alt="Step5" />
              </figure>
            </div>
            <div className="home__txt">
              <h2>
                <Trans>home-steps.step-5-header</Trans>
              </h2>
              <p>
                <Trans>home-steps.step-5-paragraph</Trans>
              </p>
            </div>
          </li>

          <li className="home__step">
            <div className="home__img">
              <figure>
                <img src={`/steps/step6-${language}.png`} alt="Step6" />
              </figure>
            </div>
            <div className="home__txt">
              <h2>
                <Trans>home-steps.step-6-header</Trans>
              </h2>
              <p>
                <Trans>home-steps.step-6-paragraph</Trans>
              </p>
            </div>
          </li>

          <li className="home__step">
            <section className="home__final-word">
              <h3 className="heading heading--sm">
                <Trans>home-steps.summary</Trans>
              </h3>
              <p>
                <Trans>home-steps.summary-download</Trans>
              </p>
              <div class="buttons">
                <a href="https://play.google.com/store/apps/details?id=de.tiramizoo.driverapp_trmz">
                  <img src={googleplay} alt="google play" />
                </a>
                <a href="https://apps.apple.com/us/app/id1525875219">
                  <img src={appstore} alt="app store" />
                </a>
              </div>
            </section>
          </li>
        </ol>
      </article>
    </Layout>
  )
}

export default IndexPage
